<template>
  <div>
    <b-card :class="$store.state.appConfig.layout.skin === 'dark' ? 'dark-card rounded-lg' : 'rounded-lg'">
      <b-row>
        <b-col md="2"></b-col>
        <b-col
          md="8"
        >
        <center v-if="loadItem">
          <b-spinner variant="primary" label="Spinning"></b-spinner>
        </center>
          <b-form v-else
            class="chat-app-form"
            @submit.prevent="submit"
          >
            <validation-observer
              class="w-100"
              ref="schoolsForm"
              #default="{invalid}"
            >
              <b-row class="pt-3">
                <b-col
                  md="6"
                  class="mb-2"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="name"
                    vid="name"
                    rules="required"
                  >
                  <b-form-group
                      label="School Name *"
                      label-for="name"
                  >
                    <b-input-group
                      class="input-group-merge form-send-message mr-1"
                    >
                      <b-form-input
                        autocomplete="off"
                        v-model="form.name"
                        placeholder="Enter School Name"
                        id="name"
                        class="custom-field"
                      />
                    </b-input-group>
                    <small class="text-danger float-left">{{ errors[0] }}</small>
                  </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  md="6"
                  class="mb-2"
                >
                  <validation-provider
                      #default="{ errors }"
                      name="number of seats"
                      vid="seats"
                      rules="required"
                    >
                    <b-form-group
                      label="Seats Number *"
                      label-for="seats"
                  >
                      <b-input-group
                        class="input-group-merge form-send-message mr-1"
                      >
                        <b-form-input
                          autocomplete="off"
                          v-model="form.total_seats"
                          placeholder="Enter Number of Seats"
                          type="number"
                          id="seats"
                          class="custom-field"
                        />
                      </b-input-group>
                      <small class="text-danger float-left">{{ errors[0] }}</small>
                  </b-form-group>
                  </validation-provider>
                </b-col>
                <!-- <b-col
                  md="6"
                  class="mb-2"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="teacher code"
                    vid="teachers_code"
                    rules="required"
                  >
                  <b-form-group
                      label="Teacher Code *"
                      label-for="teachers_code"
                  >
                    <b-input-group
                      class="input-group-merge form-send-message mr-1"
                    >
                      <b-form-input
                        autocomplete="off"
                        v-model="form.teachers_code"
                        placeholder="Enter Teacher Code"
                        id="teachers_code"
                        class="custom-field"
                      />
                    </b-input-group>
                    <small class="text-danger float-left">{{ errors[0] }}</small>
                  </b-form-group>
                  </validation-provider>
                </b-col> -->
                <!-- <b-col
                  md="6"
                  class="mb-2"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="student code"
                    vid="students_code"
                    rules="required"
                  >
                  <b-form-group
                      label="Student Code *"
                      label-for="students_code"
                  >
                    <b-input-group
                      class="input-group-merge form-send-message mr-1"
                    >
                      <b-form-input
                        autocomplete="off"
                        v-model="form.students_code"
                        placeholder="Enter Student Code"
                        id="students_code"
                        class="custom-field"
                      />
                    </b-input-group>
                    <small class="text-danger float-left">{{ errors[0] }}</small>
                  </b-form-group>
                  </validation-provider>
                </b-col> -->
                <b-col
                  md="6"
                  class="mb-2"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Expiry Date"
                    vid="expiry_date"
                    rules="required"
                  >
                  <b-form-group
                      label="Expiry date *"
                      label-for="expiry_date"
                  >
                    <b-input-group
                      class="input-group-merge form-send-message mr-1"
                    >
                      <flat-pickr
                        v-model="form.expiry_date"
                        id="expiry_date"
                        class="form-control custom-field"
                        placeholder="Expiry Date (DD-MM-YYYY)"
                        :config="{ dateFormat: 'd/m/Y'}"
                      />
                    </b-input-group>
                    <small class="text-danger float-left">{{ errors[0] }}</small>
                  </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  md="6"
                  class="mb-2"
                >
                
                  <validation-provider
                    #default="{ errors }"
                    name="Assign Courses"
                    vid="assign_courses"
                    rules="required"
                  >
                  <b-form-group
                      label="Assign Courses *"
                      label-for="assign_courses"
                  >
                  
                  <v-select
                        v-model="form.courses_id"
                        :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                        :options="courses.data"
                        id="course_id"
                        class="w-100 mb-0 custom-field"
                        :reduce="(val) => val.id"
                        placeholder="Select Course"
                        multiple
                        :label="$i18n.locale == 'en' ? 'name' : 'name_local'"
                      >
                        <template
                          v-slot:option="option"
                        >{{ $i18n.locale == "ar" ? option.name_local : option.name }}</template>
                        <template #selected-option="{ name, name_local }">
                          <div style="display: flex; align-items: baseline">
                            <strong v-if="$i18n.locale == 'ar'">{{ name_local }}</strong>
                            <strong v-else>{{ name }}</strong>
                          </div>
                        </template>
                        <template #no-options>{{ $t("noMatching") }}</template>
                      </v-select>
                    <small class="text-danger float-left">{{ errors[0] }}</small>
                  </b-form-group>
                  </validation-provider>
                </b-col>
                
                <!-- <b-col
                  md="12"
                  class="mb-2"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Enrollment Code"
                    vid="enrollment_code"
                    rules="required"
                  >
                    <b-input-group
                      class="input-group-merge form-send-message mr-1"
                    >
                      <b-form-input
                        autocomplete="off"
                        v-model="form.enrollment_code"
                        placeholder="Enter Enrollment code"
                        type="number"
                        id="enrollment_code"
                        class="custom-field"
                      />
                    </b-input-group>
                    <small class="text-danger float-left">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col> -->
              </b-row>
            </validation-observer>
            <div class="w-100 d-flex justify-content-center">
                <b-button
                  variant="outline-secondary"
                  class="rounded-pill mt-2 mr-1"
                  @click="$router.go(-1)"
                >
                  Cancel
                </b-button>
                <b-button
                  variant="primary"
                  class="rounded-pill mt-2"
                  type="submit"
                >
                  Submit
                </b-button>
              </div>
          </b-form>
        </b-col>
        <b-col md="2"></b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BCardText,
  BButton,
  BLink,
  BListGroup,
  BListGroupItem,
  BCardTitle,
  BCardBody,
  BFormGroup,
  BInputGroup,
  BFormInput,
  BForm,
  BSpinner
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import { mapGetters } from "vuex";

export default {
  components: {
    vSelect,
    BCard,
    BRow,
    BCol,
    BCardText,
    BButton,
    BLink,
    BListGroup,
    BListGroupItem,
    BCardTitle,
    BCardBody,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BForm,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    required,
    BSpinner
  },
  directives: {
    Ripple,
  },
  computed:{
    ...mapGetters({
      item: "schools/item",
      loadItem: "schools/loadItem",
      courses: "courses/items",
    }),
    id(){
      return this.$route.params.id ? this.$route.params.id : null
    }
  },
  data() {
    return {
      form:{
        name: null,
        // create_date: null,
        teachers_code: null,
        students_code: null,
        expiry_date: null,
        total_seats: null,
        courses_id:null
        // enrollment_code: null
      }
    }
  },
  methods:{
    init(){
      this.$store.dispatch("courses/list");
      if(this.id){
        this.$store.dispatch('schools/get', this.id).then(_=>{
          this.form.name = this.item.name
          // this.form.create_date = this.item.create_date
          this.form.expiry_date = new Date(this.item.expiry_date)
          this.form.total_seats = this.item.total_seats
          this.form.teachers_code = this.item.teachers_code,
          this.form.students_code = this.item.students_code
          this.form.courses_id = this.item.courses_id
          // this.form.enrollment_code = this.item.enrollment_code
        })
      }
    },
    submit(){
      this.$refs.schoolsForm.validate().then(success => {
        if (success) {
          this.form.expiry_date = this.handleDateEditFormat(this.form.expiry_date)
          if(this.id){
            const payload = { id: this.id, query: this.form }
            this.$store.dispatch( 'schools/editSchool' , payload ).then(res=>{
              this.$router.push({name:'schools'})
            }).catch(error => {
                if (error.response) {
                  this.$swal({
                    icon: 'error',
                    title: `<h4>${error.response.data.error}</h4>`,
                    showConfirmButton: true,
                    confirmButtonColor: '#E84185',
                    allowOutsideClick: true
                  });
                }
            });
          }else{
            const payload = { query: this.form }
            this.$store.dispatch( 'schools/createSchool' , payload ).then(res=>{
              this.$router.push({name:'schools'})
            }).catch(error => {
                if (error.response) {
                  this.$swal({
                    icon: 'error',
                    title: `<h4>${error.response.data.error}</h4>`,
                    showConfirmButton: true,
                    confirmButtonColor: '#E84185',
                    allowOutsideClick: true
                  });
                }
            });
          }
        }
      })
    }
  },
  mounted(){
    this.init()
  }
}
</script>

<style lang="scss">
  // @import '@core/scss/vue/pages/dashboard-ecommerce.scss';
  // @import '@core/scss/vue/libs/chart-apex.scss';
  @import '@core/scss/vue/libs/vue-flatpicker.scss';

</style>
